@import 'base.sass';

@font-face {
    font-family: 'DIN';
    src: url('../../fonts/DIN.woff2') format('woff2'), url('../../fonts/DIN.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap; }

@font-face {
    font-family: 'DIN';
    src: url('../../fonts/DIN-Medium.woff2') format('woff2'), url('../../fonts/DIN-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap; }

@font-face {
    font-family: 'DIN';
    src: url('../../fonts/DIN.woff2') format('woff2'), url('../../fonts/DIN.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap; }

@font-face {
    font-family: 'DIN';
    src: url('../../fonts/DIN-Black.woff2') format('woff2'), url('../../fonts/DIN-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap; }

@font-face {
    font-family: 'DIN';
    src: url('../../fonts/DINBold.woff2') format('woff2'), url('../../fonts/DINBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap; }

@font-face {
    font-family: 'Harman';
    src: url('../../fonts/Harman-Sans.woff2') format('woff2'), url('../../fonts/Harman-Sans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap; }

@font-face {
    font-family: 'Dear Joe';
    src: url('../../fonts/dearJoe6.woff2') format('woff2'), url('../../fonts/dearJoe6.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap; }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

html {
    font-family: 'DIN';
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    body {
        #contenido {
            h1,
            h2,
            h3 {
                color: $color3;
                font-family: 'DIN';
                font-weight: bold;
                margin-top: 30px;
                margin-bottom: 20px; }
            h1 {
                font-size: rem(30);
                line-height: rem(35); }
            h2 {
                font-size: rem(25);
                line-height: rem(30); }
            h3 {
                font-size: rem(20);
                line-height: rem(25); }
            p {
                margin-bottom: 10px; }
            svg {
                max-width: 100%; } } }
    body.home {
        header {
            position: relative;
            width: 100%;
            @media (min-width: $breakpoint1) {
                height: 100vh; }
            #banner {
                @media (min-width: $breakpoint1) {
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%; }
                .video,
                .imagen {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center; } }
            #top_header {
                position: relative;
                z-index: 10;
                background: rgba(255,255,255,0.4);
                transition: all 500ms ease-in-out; }
            #top_header.menu_abierto {
                background: $color1; } } }
    body {
        header {
            position: relative;
            z-index: 20;
            #top_header {
                background: $color1;
                #mensaje_top_header {
                    position: relative;
                    z-index: 50;
                    width: 100%;
                    color: $color3;
                    background: $color6;
                    text-align: center;
                    padding: 13px;
                    font-size: rem(15);
                    line-height: rem(14.08); }
                .cuerpo {
                    position: relative;
                    z-index: 10;
                    width: 100%;
                    max-width: $cuerpo1;
                    margin: 0 auto;
                    @media (min-width: $breakpoint1) {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-around;
                        align-items: center; }
                    #logo_head {
                        position: relative;
                        z-index: 50;
                        width: 100%;
                        padding: 15px 35px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: space-between;
                        @media (min-width: $breakpoint1) {
                            width: auto; } }
                    #menu_hamburguesa {
                        width: max-content;
                        padding: 13px 9px;
                        background: rgba(255,255,255,0.4);
                        cursor: pointer;
                        @media (min-width: $breakpoint1) {
                            display: none; }
                        .hamburguesa {
                            display: block;
                            margin: 10px auto;
                            width: 40px;
                            height: 5px;
                            background: $color3;
                            transition: all 500ms ease-in-out;
                            &:before {
                                position: relative;
                                top: -10px;
                                content: "";
                                display: block;
                                width: 40px;
                                height: 5px;
                                background: $color3;
                                transition: all 500ms ease-in-out; }
                            &:after {
                                position: relative;
                                bottom: -5px;
                                content: "";
                                display: block;
                                width: 40px;
                                height: 5px;
                                background: $color3;
                                transition: all 500ms ease-in-out; } } }
                    #menu_hamburguesa.abierto {
                        .hamburguesa {
                            background: transparent;
                            &:before {
                                background: $color4;
                                top: 0px;
                                transform: rotate(45deg); }
                            &:after {
                                background: $color4;
                                bottom: 5px;
                                transform: rotate(135deg); } } }
                    #menu_principal {
                        position: relative;
                        z-index: 20;
                        left: 0%;
                        top: -100vh;
                        opacity: 0;
                        width: 100%;
                        height: 0px;
                        transition: all 500ms ease-in-out;
                        @media (min-width: $breakpoint1) {
                            left: 0;
                            top: 0;
                            opacity: 1;
                            width: auto;
                            height: auto; }
                        #menu-menu-principal {
                            list-style: none;
                            @media (min-width: $breakpoint1) {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-items: center; }
                            .menu-item {
                                position: relative;
                                padding: 17px 47px;
                                border-bottom: 1px solid $color6;
                                overflow: hidden;
                                &:last-child {
                                    border: none; }
                                a {
                                    color: $color3;
                                    text-decoration: none;
                                    font-size: rem(20);
                                    line-height: rem(24.42);
                                    font-weight: bold; }
                                @media (min-width: $breakpoint1) {
                                    padding: 10px 20px;
                                    border: none !important;
                                    border-radius: 5px 5px 0px 0px;
                                    transition: all 300ms ease-in-out;
                                    &:hover {
                                        background: rgba(255,255,255,0.4); } } }
                            >.menu-item-has-children {
                                &:after {
                                    position: absolute;
                                    top: 23px;
                                    right: 52px;
                                    content: url('../../images/flecha.svg');
                                    cursor: pointer; }
                                @media (min-width: $breakpoint1) {
                                    position: relative;
                                    padding-right: 45px;
                                    overflow: unset;
                                    &:after {
                                        top: 12px;
                                        right: 20px; }
                                    &:hover {
                                        >.sub-menu {
                                            opacity: 1;
                                            height: auto;
                                            margin-top: 1px; } } }
                                .menu-item-has-children {
                                    &:after {
                                        display: none; } }
                                >.sub-menu {
                                    height: 0px;
                                    >.menu-item {
                                        border: none;
                                        padding: 10px 0px 10px 17px;
                                        @media (min-width: $breakpoint1) {
                                            padding: 10px 17px;
                                            background: $color1;
                                            border-radius: 0px;
                                            border-bottom: 1px solid $color6 !important;
                                            &:last-child {
                                                border-bottom: none !important; } }
                                        &:first-child {
                                            padding: 20px 0px 10px 17px;
                                            @media (min-width: $breakpoint1) {
                                                padding: 20px 17px 10px 17px; } }
                                        &:last-child {
                                            padding: 10px 0px 20px 17px;
                                            @media (min-width: $breakpoint1) {
                                                padding: 20px 17px 10px 17px; } }
                                        a {
                                            color: $color5;
                                            font-weight: normal; } }
                                    @media (min-width: $breakpoint1) {
                                        position: absolute;
                                        z-index: 30;
                                        left: 0;
                                        top: 100%;
                                        width: max-content;
                                        overflow: hidden;
                                        opacity: 0;
                                        transition: all 300ms ease-in-out; } }
                                .menu-item-has-children.abierto {
                                    &:after {
                                        transform: none !important; } } }
                            .menu-item-has-children.abierto {
                                &:after {
                                    transform: rotate(180deg); }
                                .sub-menu {
                                    height: auto; } } } }
                    #menu_principal.abierto {
                        height: auto;
                        top: 0vh;
                        opacity: 1;
                        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
                        @media (min-width: $breakpoint1) {
                            box-shadow: none; }
                        #logo_header {
                            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
                            @media (min-width: $breakpoint1) {
                                box-shadow: none; } } } }
                .rrss {
                    padding: 17px 0px 40px;
                    @media (min-width: $breakpoint1) {
                        display: none; } }
                #menu_tienda {
                    position: fixed;
                    z-index: 200;
                    bottom: 0px;
                    width: 100%;
                    background: $color4;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    align-items: center;
                    a {
                        position: relative;
                        width: 50%;
                        padding: 20px;
                        text-align: center;
                        .articulos {
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            display: block;
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            font-size: rem(16);
                            line-height: rem(20);
                            color: $color1;
                            background: $color4;
                            border-radius: 50px; }
                        svg {
                            path {
                                stroke: $color1; } } }
                    .nombre_usuario {
                        width: 100%;
                        text-align: center;
                        color: $color1;
                        background: $color2;
                        padding: 5px;
                        @media (min-width: $breakpoint1) {
                            padding: 0;
                            position: absolute;
                            top: calc(100% - 10px);
                            left: 0;
                            background: 0;
                            color: $color4;
                            font-size: rem(12);
                            line-height: rem(12);
                            text-align: left; } }

                    @media (min-width: $breakpoint1) {
                        position: relative;
                        width: auto;
                        padding: 0px;
                        background: transparent;
                        a {
                            padding: 10px 20px;
                            text-decoration: none;
                            svg {
                                path {
                                    stroke: $color4; } } } } } } }
        .rrss {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-around;
            a {
                width: 50px;
                height: 50px;
                background: $color7;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 47%;
                    max-height: 47%; } } } }
    body.single-post {
        header {
            #top_header {
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); } } } }
